// import image asset
import one from "../../images/one.png";
import two from "../../images/two.png";
import three from "../../images/three.png";
import four from "../../images/four.png";
import five from "../../images/five.png";
import six from "../../images/six.png";
import seven from "../../images/seven.png";
import eight from "../../images/eight.png";
import nine from "../../images/nine.png";
import imageTag from "../../images/imgTag.png";

export const json = {
  showProgressBar: "auto",
  // progressBarType: "pages",
  progressBarShowPageTitles: true,
  title: "Growthpad Assessment",
  progressBarType: "questions",
  // "showTOC": true,
  pages: [
    // BUSINESS OPERATIONS ASSESSMENT
    {
      // navigationTitle: "Business Operations Assessment",
      elements: [
        // No of countries
        {
          type: "image",
          name: "image1",
          imageLink: one,
          imageFit: "contain",
          imageWidth: "200px",
        },
        {
          type: "radiogroup",
          name: "In how many countries are you currently operational?",
          title: "In how many countries are you currently operational?",
          isRequired: true,
          choices: ["1", "2", "3 - 5", "6 or more"],
        },
      ],
    },
    {
      elements: [
        // Ecommerce platform
        {
          type: "image",
          name: "image1",
          imageLink: two,
          imageFit: "contain",
          imageWidth: "200px",
        },
        {
          type: "radiogroup",
          name: "Do you have an ecommerce platform?",
          title: "Do you have an ecommerce platform?",
          isRequired: true,
          choices: ["Yes", "No"],
        },
      ],
    },
    {
      elements: [
        // Available languages
        {
          type: "image",
          name: "image1",
          imageLink: three,
          imageFit: "contain",
          imageWidth: "200px",
        },
        {
          type: "radiogroup",
          name: "How many languages is your website/ecommerce platform available in?",
          title:
            "How many languages is your website/ecommerce platform available in?",
          isRequired: true,
          choices: ["1", "2", "3 or more"],
        },
      ],
    },
    {
      elements: [
        // Order tracking
        {
          type: "image",
          name: "image1",
          imageLink: four,
          imageFit: "contain",
          imageWidth: "200px",
        },
        {
          type: "radiogroup",
          name: "Are you able to track delivery of orders in real time outside Kenya?",
          title:
            "Are you able to track delivery of orders in real time outside Kenya?",
          isRequired: true,
          choices: ["Yes", "No"],
        },
      ],
    },
    {
      elements: [
        // tax regulations
        {
          type: "image",
          name: "image1",
          imageLink: five,
          imageFit: "contain",
          imageWidth: "200px",
        },
        {
          type: "radiogroup",
          name: "How many countries’ tax regulations are you familiar with?",
          title: "How many countries’ tax regulations are you familiar with?",
          isRequired: true,
          choices: ["None", "1", "2", "3", "4 or more"],
        },
      ],
    },
    // INTERNATIONAL EXPANSION READINESS
    {
      // navigationTitle: "International Expansion Readiness",
      elements: [
        // Financial revenue
        {
          type: "image",
          name: "image1",
          imageLink: six,
          imageFit: "contain",
          imageWidth: "200px",
        },
        {
          type: "radiogroup",
          name: "Do you currently have the financial revenue to expand your business outside Africa?",
          title:
            "Do you currently have the financial budget to expand your business outside Africa?",
          isRequired: true,
          choices: ["Yes", "No"],
        },
      ],
    },
    {
      elements: [
        // Global shipping partner
        {
          type: "image",
          name: "image1",
          imageLink: seven,
          imageFit: "contain",
          imageWidth: "200px",
        },
        {
          type: "radiogroup",
          name: "Do you have a global shipping partner?",
          title: "Do you have a global shipping partner?",
          isRequired: true,
          choices: ["Yes", "No"],
        },
      ],
    },
    {
      elements: [
        // Payment in foreign currencies
        {
          type: "image",
          name: "image1",
          imageLink: eight,
          imageFit: "contain",
          imageWidth: "200px",
        },
        {
          type: "radiogroup",
          name: "Do you accept payment in foreign currencies besides USD and GBP?",
          title:
            "Do you accept payment in foreign currencies besides USD and GBP?",
          isRequired: true,
          choices: ["Yes", "No"],
        },
      ],
    },
    {
      elements: [
        // International payment methods
        {
          type: "image",
          name: "image1",
          imageLink: nine,
          imageFit: "contain",
          imageWidth: "200px",
        },
        {
          type: "checkbox",
          name: "What international payment methods do you currently use/accept? (Check all that apply)",
          title:
            "What international payment methods do you currently use/accept? (Check all that apply)",
          isRequired: true,
          choices: [
            "None",
            "PayPal",
            "Ewallets",
            "Bank Transfer",
            "Visa/Mastercard",
          ],
          // if "Other" is checked, show the following question
          hasOther: true,
          otherText: "Other (please specify)",
        },
      ],
    },
    // SUBMISSION
    {
      navigationTitle: "Submission",
      elements: [
        {
          type: "image",
          name: "image1",
          imageLink: imageTag,
          imageFit: "contain",
          imageWidth: "200px",
        },
        {
          type: "text",
          name: "Please leave your email address so we can forward our recommendations to you.",
          title:
            "Please enter your email address.",
          isRequired: true,
          validators: [
            {
              type: "regex",
              text: "Please enter a valid email address",
              regex: "^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
            },
          ],
        },
      ],
    },
  ],
};
