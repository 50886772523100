import React, { useState, useEffect } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import { json } from "./json";
import CompletedAssessment from "./CompletedAssessment";
import logo from "../../images/Frame.png";

const SurveyComponent = () => {
  const [response, setResponse] = useState({});
  const [finalScore, setFinalScore] = useState(0);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const survey = new Model(json);

  useEffect(() => {
    let mainContainer = document.querySelector(".sv-components-row");

    // Create logo container and image
    let logoContainer = document.createElement("div");
    logoContainer.classList.add("logo-container");
    let img = document.createElement("img");
    img.src = logo; // Assuming 'logo' is defined elsewhere
    img.classList.add("logo");
    logoContainer.appendChild(img);

    // Insert logo container into mainContainer if it exists
    if (mainContainer) {
      mainContainer.insertBefore(logoContainer, mainContainer.firstChild);
    }
  }, []);

  survey.onComplete.add((sender, options) => {
    const score = awardScores(sender.data);
    setFinalScore(score);
    // console.log(JSON.stringify(sender.data, null, 3));
    setResponse(sender.data);
    setSurveyCompleted(true);

    // Extract email address from the survey response
    const email =
      sender.data[
        "Please leave your email address so we can forward our recommendations to you"
      ];

    // Send survey data to PHP endpoint
    fetch("survey_submit.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...sender.data,
        email: email, // Include email address in the data sent to the server
        finalScore: score, // Include final score in the data sent to the server
      }),
    })
      .then((response) => response.text())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  });

  // award test scores based on the response
  const awardScores = (response) => {
    let score = 0;
    for (let key in response) {
      // no of countries
      if (key === "In how many countries are you currently operational?") {
        switch (response[key]) {
          case "1":
            score = 1;
            break;
          case "2":
            score = 5;
            break;
          case "3 - 5":
            score = 8;
            break;
          case "6 or more":
            score = 10;
            break;
          default:
            break;
        }
      }
      // ecommerce platform
      if (key === "Do you have an ecommerce platform?") {
        if (response[key] === "Yes") {
          score = score + 10;
        } else {
          score = score + 0;
        }
      }
      // available languages
      if (
        key ===
        "How many languages is your website/ecommerce platform available in?"
      ) {
        switch (response[key]) {
          case "1":
            score = score + 1;
            break;
          case "2":
            score = score + 6;
            break;
          case "3 or more":
            score = score + 10;
            break;
          default:
            break;
        }
      }
      // order tracking
      if (
        key ===
        "Are you able to track delivery of orders in real time outside Kenya?"
      ) {
        if (response[key] === "Yes") {
          score = score + 10;
        } else {
          score = score + 0;
        }
      }
      // tax regulations
      if (
        key === "How many countries’ tax regulations are you familiar with?"
      ) {
        switch (response[key]) {
          case "None":
            score = score + 0;
            break;
          case "1":
            score = score + 1;
            break;
          case "2":
            score = score + 4;
            break;
          case "3":
            score = score + 7;
            break;
          case "4 or more":
            score = score + 10;
            break;
          default:
            break;
        }
      }
      // financial revenue
      if (
        key ===
        "Do you currently have the financial revenue to expand your business outside Africa?"
      ) {
        if (response[key] === "Yes") {
          score = score + 10;
        } else {
          score = score + 0;
        }
      }
      // global shipping partner
      if (key === "Do you have a global shipping partner?") {
        if (response[key] === "Yes") {
          score = score + 10;
        } else {
          score = score + 0;
        }
      }
      // payment currencies
      if (
        key ===
        "Do you accept payment in foreign currencies besides USD and GBP?"
      ) {
        if (response[key] === "Yes") {
          score = score + 10;
        } else {
          score = score + 0;
        }
      }
      // payment methods
      if (
        key ===
        "What international payment methods do you currently use/accept? (Check all that apply)"
      ) {
        // if response is None
        if (response[key][0] === "None") {
          score = score + 0;
          break;
        } else {
          // check for array length
          if (response[key].length === 1) {
            score = score + 1;
          } else if (response[key].length === 2) {
            score = score + 5;
          } else if (response[key].length === 3) {
            score = score + 9;
          } else if (response[key].length === 4) {
            score = score + 12;
          } else if (response[key].length === 5) {
            score = score + 15;
          } else {
            score = score + 0;
          }
        }
      }
    }
    return score;
  };

  // final score assessment
  const finalScoreDescription = (score) => {
    if (score <= 30) {
      return (
        <div className="my-4 tracking-wider">
          <p className="font-bold mb-5">Not Ready!</p>
          <p>
            Your business still indicates some need for further improvement in
            market expansion readiness from infrastructure to digital
            capabilities.{" "}
          </p>
        </div>
      );
    } else if (score > 30 && score <= 60) {
      return (
        <div className="my-4 tracking-wider">
          <p className="font-bold mb-5">Almost Ready!</p>
          <p>
            Your business is showing signs of steady readiness for expansion to
            overseas markets. This expansion may not be immediate but with
            research and further assessment you can determine if this is an
            immediate action or future action.
          </p>
        </div>
      );
    } else if (score > 60 && score <= 100) {
      return (
        <div className="my-4 tracking-wider">
          <p className="mb-5 font-bold">Ready for Expansion!</p>
          <p>
            Your business shows signs of readiness for global expansion. You
            have built the infrastructure and digital capabilities that can
            enable a takeover for new markets.
          </p>
        </div>
      );
    }
  };

  return (
    <>
      {!surveyCompleted && <Survey model={survey} />}
      {surveyCompleted && (
        <CompletedAssessment
          response={response}
          finalScore={finalScore}
          finalScoreDescription={finalScoreDescription}
        />
      )}
    </>
  );
};

export default SurveyComponent;
