import React from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import mainImage from "../images/main image.png";
import logo from "../images/Frame.png";

const LandingPage = () => {
  const handleStartAssessment = () => {
    // Navigate to the assessment page and trigger full page reload
    window.location.href = "/assessment";
  };
  return (
    <>
      <main className="p-12 flex flex-wrap gap-10 justify-center">
        {/* image */}
        <div className="md:w-1/2 sm:w-full">
          <div>
            <img src={mainImage} alt="globe" />
          </div>
        </div>
        {/* second section */}
        <div className="sm:w-3/4 md:w-1/3 flex flex-wrap gap-10 flex-row md:flex-column justify-center">
          <div className="bg-homeColor p-8 rounded-3xl w-full">
            <img src={logo} alt="logo" className="w-28" />
            <h2 className="text-white text-2xl font-semibold mt-8 tracking-wider">
              Expand Your <br />
              Business Globally
            </h2>
          </div>
          <div className="bg-homeColor p-8 rounded-3xl w-full">
            <p className="text-white mt-4 mb-8 tracking-wider">
              Ready to take your business to the global stage? Get started with
              our assessment today!
            </p>
            <button
              className="bg-white text-buttonColor w-full rounded-3xl p-3 flex justify-center gap-3 relative hover:bg-buttonColor hover:text-white transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
              onClick={handleStartAssessment}
            >
              Get Started
              <MdOutlineArrowForwardIos className="inline text-3xl text-homeColor rounded-full p-1 bg-buttonColor absolute right-2 top-2" />
            </button>
          </div>
        </div>
      </main>
    </>
  );
};
export default LandingPage;
