import React, { useEffect } from "react";
import { IoIosCloseCircle } from "react-icons/io";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const CompletedAssessment = ({
  response,
  finalScore,
  finalScoreDescription,
}) => {
  const navigate = useNavigate();
  // link to calendly to schedule a meeting
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // handle show calendly schedule
  const showCalendly = () => {
    const calendly = document.querySelector(".calen");
    calendly.classList.toggle("hidden");
  };

  // handle close popup
  const closePopup = () => {
    const calendly = document.querySelector(".calen");
    calendly.classList.toggle("hidden");
    navigate("/");
  };

  return (
    <section className="p-5 m-5 md:p-20 md:m-20 bg-results-bg bg-center rounded-3xl font-hankengrotesk results_sect flex items-center">
      <div>
        <div className="md:px-10">
          {/* score */}
          <div className="bg-white rounded-3xl p-5 flex flex-col gap-4 md:flex-row md:justify-between md:items-center">
            <h2 className="text-3xl tracking-wider">Your Score</h2>
            <p className="flex flex-col md:items-center justify-center">
              <span className=" text-4xl font-semibold">{finalScore}</span>
              <span>out of 100</span>
            </p>
          </div>
          {/* description and CTA*/}
          <div className="flex mt-5 gap-4 flex-col lg:flex-row lg:justify-between text-buttonColor">
            <p className="bg-white rounded-3xl w-full lg:w-7/12 p-5 md:px-10 md:py-5">
              {finalScoreDescription(finalScore)}
            </p>
            <div className="bg-white rounded-3xl w-full lg:w-5/12 p-5 md:px-10 md:py-5">
              <p className="my-4">
                Schedule an exploratory call with one of our market expansion
                experts to discover how we can help your business reach new
                markets across the globe.
              </p>
              <button
                onClick={showCalendly}
                className="bg-buttonColor sm:hover:green-500 hover:bg-gradient-to-t
                 from-buttonColor to-green-600 active:bg-gradient-to-b text-white p-3 rounded-full text-xl sm:text-lg cursor-pointer w-full flex items-center gap-3 flex-wrap schedule_btn"
              >
                <span>Schedule a meeting</span>
                <MdOutlineArrowForwardIos className="inline text-4xl text-buttonColor rounded-full p-1 bg-white" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* popup */}
      <div className="fixed inset-0 flex items-center justify-center calen hidden bg-results-bg bg-center min-h-screen calendly_bg">
        <div className="absolute inset-0 flex items-center justify-center">
          <div
            className="calendly-inline-widget w-full h-full"
            data-url="https://calendly.com/gdcboardroombooking/30min"
          ></div>
          <div
            onClick={closePopup}
            className="absolute top-0 right-5 p-2 cursor-pointer font-bold bg-buttonColor rounded-3xl close_popup"
          >
            <IoIosCloseCircle className="text-5xl text-red-500 " />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompletedAssessment;
