import { Routes, Route } from "react-router-dom";
import LandingPage from "./Components/LandingPage";
import Survey from "./Components/surveys/Survey";

const App = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/assessment" element={<Survey />} />
      </Routes>
    </div>
  );
};

export default App;
